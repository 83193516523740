import Layout from "../components/layout";
import Header from "../components/header";
import Helmet from "react-helmet";
import React from "react";
import {graphql} from "gatsby";

const BlogCategoryPage = ({data}) => {
    let siteType = process.env.GATSBY_API_URL;
    let blogData;
    if (siteType === "prod") {
        blogData = data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published");
    } else if (siteType === "dev") {
        blogData = data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
    }
    blogData = blogData.filter(blog => blog.node.category.name === data.strapiBlogCategories.name);
    blogData.sort((a, b) => new Date(b.node.publishDate) - new Date(a.node.publishDate));

    function formatDate(date) {
        var monthNames = [
            "Januar", "Februar", "März",
            "April", "Mai", "Juni", "Juli",
            "August", "September", "Oktober",
            "November", "Dezember"
        ];

        let updateDate = date.split("-");
        let day = updateDate[2];
        let monthIndex = updateDate[1];
        let year = updateDate[0];

        return day + ". " + monthNames[monthIndex - 1] + " " + year;
    }

    function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            darkColor : lightColor;
    }

    let offersData = data.allStrapiOfferPages.edges;
    return (
        <Layout page="blog">
            <Header className="blog" slogan={data.allStrapiBlogPages.edges[0].node.slogan}
                    blogphoto={{backgroundImage: `url(${data.allStrapiBlogPages.edges[0].node.photo.publicURL})`}}
                    offersData={offersData}/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Blogbeiträge zu «{data.strapiBlogCategories.name}» | Paixon</title>
                <meta name="description" content={data.strapiBlogCategories.meta_description}/>
                <style type="text/css">{`
            body {
                background-image: none;
            }
          `}</style>
            </Helmet>
            <main className="pt container blog">
                <h1>Paixon Blog</h1>

                <div className="blogsCategory">
                    <a
                        className="categoryButton alleKategorienInactive"
                        href={`/blog`}
                        title='Alle Kategorien'
                    >
                        <p>Alle Kategorien</p>
                    </a>
                    {data.allStrapiBlogCategories.edges.map(category => {
                        if (category.node.name === data.strapiBlogCategories.name) {
                            let textColor = pickTextColorBasedOnBgColorSimple(category.node.color, 'white', '#38373C');
                            return (
                                <a
                                    className="categoryButton categoryActive"
                                    href={`/blog/${category.node.url}`}
                                    title={category.node.name}
                                    style={{
                                        borderColor: category.node.color,
                                        backgroundColor: category.node.color,
                                        color: textColor
                                    }}
                                >
                                    <p>{category.node.name}</p>
                                </a>
                            )
                        } else {
                            return (
                                <a
                                    className="categoryButton"
                                    href={`/blog/${category.node.url}`}
                                    title={category.node.name}
                                    style={{borderColor: category.node.color}}
                                >
                                    <p>{category.node.name}</p>
                                </a>
                            )
                        }
                    })}
                </div>

                <section>
                    <div className="blogFlexcontainer">
                        {blogData.map(edge => {
                            const blogpost = edge.node;
                            let updateDate = formatDate(blogpost.publishDate);
                            return (
                                <div className="element nopadding">
                                    <div className="blogcolumn-element">
                                        <div className="blogcolumn">
                                            <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                <div className="blogline"
                                                     style={{
                                                         borderBottom: `10px solid ${blogpost.category.color}`,
                                                         backgroundImage: `url(${blogpost.blog_photo.publicURL})`
                                                     }}>
                                                    <div className="category-icon">
                                                        <div className="triangle-up"
                                                             style={{borderBottom: `45px solid ${blogpost.category.color}`}}>
                                                        </div>
                                                        <img
                                                            src={data.strapiBlogCategories.blog_category_icon.publicURL}
                                                            alt={`${blogpost.category.name} icon`}/>
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="bloginfo">
                                                <p className="publishInfos">{updateDate}</p>
                                                <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                    <h3>{blogpost.title}</h3></a>
                                                <span className="blogpostsummary">{blogpost.summary}</span>
                                            </div>
                                        </div>
                                        <div className="readbloglink">
                                            <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                Weiterlesen
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
            </main>
        </Layout>
)
}

export default BlogCategoryPage

export const pageQuery = graphql`
query BlogCategoryQuery($blogCategoryUrl: String!)
    {
        strapiBlogCategories(url
    :
        {
            eq: $blogCategoryUrl
        }
    )
        {
            id
            url
            meta_description
            name
            blog_category_icon
            {
                publicURL
            }
        }
        allStrapiBlogCategories
        {
            edges
            {
                node
                {
                    color
                    name
                    url
                    blog_category_icon
                    {
                        publicURL
                    }
                }
            }
        }
        allStrapiBlogPages
        {
            edges
            {
                node
                {
                    slogan
                    photo
                    {
                        publicURL
                    }
                }
            }
        }
        allStrapiBlogs
        {
            edges
            {
                node
                {
                    author
                    {
                        name
                    }
                    content
                    id
                    language
                    publishDate
                    title
                    summary
                    urlName
                    status
                    category
                    {
                        name
                        color
                        url
                    }
                    blog_photo
                    {
                        publicURL
                    }
                }
            }
        }
        allStrapiOfferPages
        {
            edges
            {
                node
                {
                    title
                    url
                    order
                }
            }
        }
    }
`
